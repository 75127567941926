<template>
  <div class="app-container">
    <div class="page-title" style="display:flex; flex-direction:row">
      <h2>{{ $t('producteur.producteurs') }}</h2>
      <h2>👨🏻‍🌾</h2>
    </div>
    <el-row :gutter="40" class="panel-group">
      <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
        <div class="card-panel">
          <div class="card-panel-icon-wrapper icon-people">
            <h1>👨🏻‍🌾</h1>
          </div>
          <div class="card-panel-description">
            <div class="card-panel-text">
              Total Producteurs
            </div>
            <count-to :start-val="0" :end-val="total" :duration="2600" class="card-panel-num" />
          </div>
        </div>
      </el-col>
    </el-row>
    <el-card v-if="checkRole(['admin'])" class="box-card filter-card just-for-admin">
      <div slot="header" class="clearfix">
        <span>{{ $t('general.filter') }}</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="initSearch">{{ $t('general.init') }}</el-button>
      </div>
      <div class="filter-container">
        <el-radio v-model="activeList" label="desactives" border @change="getSubProducteursList('indexdesactive')">{{ $t('general.F_desactives') }}</el-radio>
        <el-radio v-model="activeList" label="disponibles" border @change="handleFilterProducteurs">{{ $t('general.disponibles') }}</el-radio>
        <el-radio v-model="activeList" label="corbeille" class="danger-radio" border @change="getSubProducteursList('indexinvisible')">{{ $t('general.corbeille') }}</el-radio>
      </div>
    </el-card>

    <el-card class="box-card">
      <div class="filter-container">
        <!-- <el-input v-model="query.keyword" :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" @keyup.enter.native="handleFilterProducteurs" /> -->
        <el-input v-model="query.keyword" :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" clearable @input="handleFilterProducteurs" />
        <el-button v-waves class="mr-15 filter-item" type="primary" icon="el-icon-search" @click="handleFilterProducteurs">
          {{ $t('general.search') }}
        </el-button>
        <el-button v-waves :loading="downloading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload">
          {{ $t('general.export') }}
        </el-button>
        <el-button v-if="checkPermission(['creer producteur'])" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="handlecreateProducteur">
          {{ $t('general.add') }}
        </el-button>
      </div>
      <el-table v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%">
        <el-table-column align="center" label="Code" min-width="100px">
          <template slot-scope="scope">
            <el-link>
              <span>{{ scope.row.code }}</span>
            </el-link>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('producteur.nom')" min-width="200px">
          <template slot-scope="scope">
            <span>{{ scope.row.nom + ' ' + scope.row.prenom }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('producteur.numero')" min-width="150px">
          <template slot-scope="scope">
            <span>{{ scope.row.numero }}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="checkPermission(['activer producteur'])" align="center" :label="$t('general.status')" width="100">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_active"
              @change="setProducteurStatus(scope.row.id,'active')"
            />
          </template>
        </el-table-column>

        <el-table-column v-if="checkRole(['admin']) && (activeList=='corbeille' || query.keyword)" align="center" :label="$t('general.restaurer')" width="100">
          <template slot-scope="scope">
            <el-switch
              v-if="!scope.row.is_visible"
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_visible"
              @change="setProducteurStatus(scope.row.id,'visible')"
            />
          </template>
        </el-table-column>

        <el-table-column v-if="checkPermission(['modifier producteur','visible producteur','supprimer producteur'])" align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="200">
          <template slot-scope="scope">
            <el-button v-if="scope.row.is_visible && checkPermission(['modifier producteur'])" type="primary" size="small" icon="el-icon-edit" style="margin-right:10px;" @click="handleEditProducteur(scope.row.id);" />
            <el-button v-if="checkPermission(['visible producteur','supprimer producteur'])" slot="reference" type="danger" size="small" icon="el-icon-delete" style="margin-right:10px;" @click="handleDeleteProducteur(scope.row.id, scope.row.is_visible, scope.row.nom + ' '+scope.row.prenom );" />
            <el-popover
              placement="bottom"
              title="Autres options"
              width="200"
              trigger="click"
            >
              <el-button v-if="true" style="margin-left:0px; margin-top:3px" @click="handleParcelleDialog(scope.row)">Affecter une Parcelle</el-button>
              <el-button slot="reference" type="info" size="small" icon="el-icon-more" />
              <el-button style="margin-left:0px; margin-top:3px" @click="printRedirect(scope.row.id)"> Détails </el-button>
            </el-popover>
          </template>
        </el-table-column>

      </el-table>
      <pagination v-if="total > 0" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getProducteursList" />

      <!-- ======= ADD / EDIT PRODUCTEURS ======= -->
      <el-dialog
        :title="editing ? $t('producteur.edit') + ' ' + currentProducteur.libelle : $t('producteur.AddProducteur')"
        :visible.sync="dialogCreateProducteur"
        :before-close="handleCloseCampagneagricoleCreateDraw"
        :loading="producteurCreating"
        width="30%"
      >
        <div class="content-inputs">
          <el-form ref="producteurForm" :rules="rules" :model="currentProducteur" label-position="left">
            <el-form-item :label="$t('controleur.controleur')" prop="controleur_id">
              <el-select v-model="currentProducteur.controleur_id" class="filter-item" :placeholder="$t('controleur.controleur')" style="width: 100%;" prop="controleur_id" @change="getGroupementsList(currentProducteur.controleur_id)">
                <el-option
                  v-for="(controleur, index) in controleurs"
                  :key="index"
                  :label="controleur.nom | uppercaseFirst"
                  :value="controleur.id"
                  :disabled="!controleur.is_active"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('groupement.groupement')" prop="groupement_id">
              <el-select v-model="currentProducteur.groupement_id" class="filter-item" :placeholder="$t('groupement.groupement')" filterable style="width: 100%;" prop="groupement_id">
                <el-option
                  v-for="(groupement, index) in groupements"
                  :key="index"
                  :label="groupement.nom | uppercaseFirst"
                  :value="groupement.id"
                  :disabled="!groupement.is_active"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('village.village')" prop="village_id">
              <el-select v-model="currentProducteur.village_id" class="filter-item" :placeholder="$t('village.village')" filterable style="width: 100%;" prop="village_id">
                <el-option
                  v-for="(village, index) in villages"
                  :key="index"
                  :label="village.nom | uppercaseFirst"
                  :value="village.id"
                  :disabled="!village.is_active"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('producteur.nom')" prop="nom">
              <el-input v-model="currentProducteur.nom" />
            </el-form-item>
            <el-form-item :label="$t('producteur.prenom')" prop="prenom">
              <el-input v-model="currentProducteur.prenom" />
            </el-form-item>
            <el-form-item :label="$t('producteur.email')" prop="email">
              <el-input v-model="currentProducteur.email" />
            </el-form-item>
            <el-form-item :label="$t('producteur.numero')" prop="numero">
              <el-input v-model="currentProducteur.numero" />
            </el-form-item>
            <!--el-form-item :label="$t('producteur.adresse')" prop="adresse">
              <el-input v-model="currentProducteur.adresse" />
            </el-form-item-->
            <el-radio-group v-model="sexe" size="medium">
              <el-radio-button :label="'m'">Homme</el-radio-button>
              <el-radio-button :label="'f'">Femme</el-radio-button>
            </el-radio-group>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="dialogCreateProducteur=false">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button type="primary" :loading="producteurCreating" @click="editing ? updateProducteur() : createProducteur()">
              {{ producteurCreating ? $t('general.saving') : $t('general.save') }}
            </el-button>
          </div>
        </span>
      </el-dialog>

      <el-dialog
        title="Affectation de parcelles"
        :visible.sync="parcelleAffectDialog"
        :before-close="handleCloseCampagneagricoleCreateDraw"
        width="72%"
        center
      >
        <h3>{{ currentParcelle.user }}</h3>
        <el-form ref="parcelForm" :inline="true" :model="currentParcelle" label-position="top" class="demo-ruleForm">
          <el-form-item
            prop="village_id"
            :label="$t('parcelle.village_id')"
            :rules="[
              { required: true, message: 'veuillez renseigner ce champ', trigger: 'blur' },
            ]"
          >
            <el-select v-model="currentParcelle.village_id" class="filter-item" :placeholder="$t('village.village')" style="width: 100%;" prop="village_id">
              <el-option
                v-for="(village, index) in villages"
                :key="index"
                :label="village.nom | uppercaseFirst"
                :value="village.id"
                :disabled="!village.is_active"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            prop="superficie"
            :label="$t('parcelle.superficie')"
            :rules="[
              { required: true, message: $t('parcelle.SuperficieRequired'), trigger: 'blur' },
              { type: 'number', message: 'Veuillez entrer un nombre', trigger: ['blur', 'change'] }
            ]"
          >
            <el-input-number v-model="currentParcelle.superficie" :controls="false" style="width: 100%" :min="0" controls-position="right" :placeholder="$t('parcelle.superficie')" required />
          </el-form-item>
          <el-form-item :label="$t('parcelle.acc_saison')" prop="saison">
            <el-input-number v-model="currentParcelle.acc_saison" :controls="false" style="width: 100%" :min="0" controls-position="right" :placeholder="$t('parcelle.acc_saison')" />
          </el-form-item>
          <el-form-item :label="$t('parcelle.dist_hab')" prop="distance">
            <el-input-number v-model="currentParcelle.dist_hab" :controls="false" style="width: 100%" :min="0" controls-position="right" :placeholder="$t('parcelle.dist_hab')" />
          </el-form-item>
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item :label="$t('parcelle.nbr_arb')" prop="arbre">
              <el-input-number v-model="currentParcelle.nbr_arb" :controls="false" style="width: 100%" :min="0" controls-position="right" :placeholder="$t('parcelle.nbr_arb')" />
            </el-form-item>
            <el-form-item :label="$t('parcelle.riviere')" prop="riviere">
              <el-input-number v-model="currentParcelle.riviere" :controls="false" style="width: 100%" :min="0" controls-position="right" :placeholder="$t('parcelle.riviere')" />
            </el-form-item>
            <el-form-item :label="$t('parcelle.date_dernier_usage')" prop="date_usage">
              <el-input v-model="currentParcelle.date_dernier_usage" :placeholder="$t('parcelle.date_dernier_usage')" />
            </el-form-item>
            <el-form-item :label="$t('parcelle.observation')" prop="observation">
              <el-input v-model="currentParcelle.observation" prefix=" " type="textarea" :placeholder="$t('parcelle.observation')" />
            </el-form-item>
          </el-form>
          <el-form :inline="true" class="demo-form-inline">
            <!-- <el-form-item :label="$t('parcelle.statut')" prop="statut">
              <el-input v-model="currentParcelle.statut" style="width: 100%" :min="0" controls-position="right" :placeholder="$t('parcelle.statut')" />
            </el-form-item> -->
            <el-form-item :label="$t('parcelle.libelle')" prop="libelle">
              <el-input v-model="currentParcelle.libelle" style="width: 100%" :min="0" controls-position="right" :placeholder="$t('parcelle.libelle')" />
            </el-form-item>
            <el-form-item :label="$t('parcelle.zone_tp')" prop="zone">
              <el-select v-model="currentParcelle.zone_tp" style="width: 100%" :placeholder="$t('parcelle.zone_tp')">
                <el-option
                  :label="$t('general.yes')"
                  :value="true"
                />
                <el-option
                  :label="$t('general.no')"
                  :value="false"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('parcelle.dist_zn_tp')" prop="distance_ztp">
              <el-input-number v-model="currentParcelle.dist_zn_tp" :controls="false" style="width: 100%" :min="0" controls-position="right" :placeholder="$t('parcelle.dist_zn_tp')" />
            </el-form-item>
            <el-form-item :label="$t('parcelle.cult_zn_tp')" prop="cult_zn_tp">
              <el-input v-model="currentParcelle.cult_zn_tp" type="text" :placeholder="$t('parcelle.cult_zn_tp')" />
            </el-form-item>
          </el-form>
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item :label="$t('parcelle.visa_ci')" prop="visa_ci">
              <el-select v-model="currentParcelle.visa_ci" :controls="false" style="width: 100%" :placeholder="$t('parcelle.visa_ci')">
                <el-option
                  :label="$t('general.yes')"
                  :value="true"
                />
                <el-option
                  :label="$t('general.no')"
                  :value="false"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('parcelle.visa_prod')" prop="visa_prod">
              <el-select v-model="currentParcelle.visa_prod" style="width: 100%" :placeholder="$t('parcelle.visa_prod')">
                <el-option
                  :label="$t('general.yes')"
                  :value="true"
                />
                <el-option
                  :label="$t('general.no')"
                  :value="false"
                />
              </el-select>
            </el-form-item>
          </el-form>
          Historiques <el-button type="primary" icon="el-icon-plus" circle @click="addHistorique" /> ( cliquez sur + pour ajouter une historique)
          <div v-for="(historique, counter) in historiques" :key="counter">
            <el-form ref="historiqueForm" :inline="true" :model="{historiques}" class="demo-form-inline">
              <el-form-item :label="$t('parcelle.annee')" prop="annee">
                <el-input v-model="historique.annee" placeholder="Annee" />
              </el-form-item>
              <el-form-item :label="$t('parcelle.culture')" prop="culture">
                <el-input v-model="historique.culture_pratique" placeholder="Culture" />
              </el-form-item>
              <el-form-item :label="$t('parcelle.angrais')" prop="angrais">
                <el-select v-model="historique.usage_angrais" style="width: 100%" placeholder="Usage Angrais">
                  <el-option
                    :label="$t('general.yes')"
                    :value="true"
                  />
                  <el-option
                    :label="$t('general.no')"
                    :value="false"
                  />
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('parcelle.semence_ogm')" prop="semence_ogm">
                <el-select v-model="historique.semence_ogm" style="width: 100%" placeholder="semence ogm">
                  <el-option
                    :label="$t('general.yes')"
                    :value="true"
                  />
                  <el-option
                    :label="$t('general.no')"
                    :value="false"
                  />
                </el-select>
              </el-form-item>
              <el-button type="danger" icon="el-icon-delete" circle @click="deleteHistorique" />
            </el-form>
          </div>
          <el-button @click="parcelleAffectDialog = false">Annuler</el-button>
          <el-button type="primary" :loading="parcelleCreating" @click="createParcelle()">
            {{ $t('general.saving') }}
          </el-button>
        </el-form>
      </el-dialog>
      <!-- ======= REGION USER DIALOG ======= -->
    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
import CountTo from 'vue-count-to';

const parcelleResource = new Resource('parcelles');
const producteurResource = new Resource('producteurs');
const producteurResourceAll = new Resource('producteurs-all');
const controleurResource = new Resource('controleurs');
const groupementResource = new Resource('groupements');
const villageResource = new Resource('villages');
export default {
  name: 'ProducteursList',
  components: { Pagination, CountTo },
  directives: { waves, permission, role },
  data() {
    return {
      histLengh: 0,
      list: null,
      total: 0,
      parcelleAffectDialog: false,
      sexe: '',
      loading: true,
      downloading: false,
      villages: null,
      listback: null,
      groupements: null,
      controleurs: null,
      activeList: 'disponibles',
      producteurCreating: false,
      parcelleCreating: false,
      editing: false,
      currentProducteur: {},
      currentParcelle: {
        acc_saison: 0,
        dist_hab: 0,
        nbr_arb: 0,
        riviere: 0,
        dist_zn_tp: 0,
        date_dernier_usage: '',
        observation: '',
        // statut: '',
        libelle: '',
        zone_tp: false,
        cult_zn_tp: '',
        visa_ci: false,
        visa_prod: false,
      },
      historiques: [
        {
          annee: '',
          culture_pratique: '',
          usage_angrais: false,
          semence_ogm: false,
        },
      ],
      query: {
        page: 1,
        limit: 15,
        keyword: '',
      },
      dialogCreateProducteur: false,
      currentProducteurId: 0,
      rules: {
        email: [{ required: true, message: 'Email requis', trigger: 'blur' }],
        nom: [{ required: true, message: this.$t('producteur.NomRequired'), trigger: 'blur' }],
        prenom: [{ required: true, message: this.$t('producteur.PrenomRequired'), trigger: 'blur' }],
        numero: [{ required: true, message: this.$t('producteur.NumeroRequired'), trigger: 'blur' }],
        adresse: [{ required: true, message: this.$t('producteur.AdresseRequired'), trigger: 'blur' }],
      },
      rules_parcelle: {
        observation: [{ required: true, message: this.$t('parcelle.ObservationRequired'), trigger: 'blur' }],
        superficie: [{ required: true, message: this.$t('parcelle.SupperficeRequired'), trigger: 'blur' }],
      },
      nameSearch: '',
      fileList: [],
      mininfos: [
        {
          'name1': 'Code UP',
          'val1': '1',
          'name2': 'Nom du producteur',
          'val2': '2',
        },
        {
          'name1': 'Zone',
          'val1': '1',
          'name2': 'Sexe (M/F)',
          'val2': '2',
        },
        {
          'name1': 'Commune',
          'val1': '1',
          'name2': 'Code Parcelle/Date d’intégration Parcelle',
          'val2': '2',
        },
        {
          'name1': 'Village',
          'val1': '1',
          'name2': 'Code Producteur',
          'val2': '2',
        },
        {
          'name1': 'Groupement',
          'val1': '1',
          'name2': 'GPS de la parcelle',
          'val2': '2',
        },
      ],
    };
  },
  computed: {

  },
  created() {
    this.resetNewProducteur();
    this.getProducteursList();
    this.getControleursList();
    this.getGroupementsList();
    this.getVillagesList();
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    handleCloseCampagneagricoleCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    handleParcelleDialog(pass) {
      this.currentParcelle.user = pass.nom + ' ' + pass.prenom;
      this.currentParcelle.producteur_id = pass.id;
      this.parcelleAffectDialog = true;
      this.$nextTick(() => {
        this.$refs['parcelForm'].clearValidate();
      });
    },
    addHistorique(){
      this.historiques.push({
        annee: '',
        culture_pratique: '',
        usage_angrais: null,
        semence_ogm: null,
      });
      this.histLengh = this.historiques.length;
    },
    deleteHistorique(counter){
      this.historiques.splice(counter, 1);
    },
    // en cours ....
    async affecterParcelleProducteur(){
      const donnees = {
        'parcelles': [
          this.currentParcelleId,
        ],
      };
      const data = await producteurResource.extra('affectation-parcelle/' + this.currentParcelle.producteur_id, donnees);
      if (data.success){
        this.$message({
          type: 'success',
          message: 'Parcelle affecté à ' + this.currentParcelle.user + ' avec succès',
        });
        this.initSearch();
        this.parcelleAffectDialog = false;
        this.currentParcelle = null;
      } else {
        console.log('SUCCEEDED ?', data);
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
        this.zoneAffectationDiolog = false;
        this.currentParcelle = null;
      }
    },
    frontFilter(value){
      this.loading = true;
      const result = this.listback.filter((element) => {
        return element.nom.toLowerCase().includes(this.nameSearch.toLowerCase()) || element.prenom.toLowerCase().includes(this.nameSearch.toLowerCase());
      });
      this.list = result;
      this.loading = false;
    },
    cancelproducteurForm() {
      this.$refs.CampagneagricoleCreationDrawer.closeDrawer();
    },
    async setProducteurStatus(id, type){
      const data = await producteurResource.get('toogle_' + type + '/' + id);
      if (data.success){
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.initSearch();
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    printRedirect(id) {
      // var routeData = this.$router.resolve({ path: '/producteur/' + id });
      // window.open(routeData.href, '_blank');
      console.log('The router', this.$router);
      this.$router.push({ path: '/producteurs/' + id });
      console.log('The router', this.$router);
    },
    initSearch(){
      this.handleFilterProducteurs();
    },
    async getProducteursList() {
      this.loading = true;
      const { data, meta } = await producteurResourceAll.sublist('?typeApi="all"', this.query);
      this.list = data.reverse();
      this.listback = data.reverse();
      this.total = meta.total;
      console.log('===== EDI', this.total);
      console.log(this.list);
      console.log('===== EVO');
      this.loading = false;
    },
    async getControleursList() {
      const { data } = await controleurResource.list();
      this.controleurs = data;
      console.log('CONTROLEURS ', this.controleurs);
    },
    async getGroupementsList(value) {
      const { data } = await groupementResource.list({ controleur_id: value, limit: 1000 });
      this.groupements = data;
    },
    async getVillagesList() {
      const { data } = await villageResource.sublist('?typeApi="all"', this.query);
      this.villages = data;
    },
    async getSubProducteursList(subUrl) {
      this.loading = true;
      const { data } = await producteurResource.sublist('/' + subUrl, this.query);
      this.list = data;
      this.loading = false;
    },
    handleFilterProducteurs() {
      this.query.page = 1;
      this.getProducteursList();
    },
    handlecreateProducteur() {
      this.editing = false;
      this.resetNewProducteur();
      this.dialogCreateProducteur = true;
      this.$nextTick(() => {
        this.$refs['producteurForm'].clearValidate();
      });
    },
    handleEditProducteur(id){
      this.editing = true;
      this.resetNewProducteur();
      this.currentProducteur = this.list.find(abonne => abonne.id === id);
      this.dialogCreateProducteur = true;
      this.$nextTick(() => {
        this.$refs['producteurForm'].clearValidate();
      });
    },
    handleDeleteProducteur(id, visible, libelle) {
      console.log('id : ' + id + 'libelle : ' + libelle);
      this.$confirm(this.$t('producteur.deleteWarn') + ' ' + libelle + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        console.log('result : ');
        if (!visible && (checkRole(['admin']) || checkPermission(['supprimer producteur']))) {
          console.log('result 2 : ');
          this.$swal({
            title: this.$t('producteur.LastGard'),
            text: this.$t('general.irrversibleMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('general.ouiSupprimer'),
          }).then((result) => {
            if (result.isConfirmed) {
              producteurResource.destroy(id).then(response => {
                this.$message({
                  type: 'success',
                  message: this.$t('producteur.suppressionEffectue'),
                });
                this.initSearch();
              }).catch(error => {
                console.log(error);
              });
            }
          });
        } else {
          this.setProducteurStatus(id, 'visible');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    createProducteur() {
      this.$refs['producteurForm'].validate((valid) => {
        console.log(this.sexe !== '');
        if (valid && this.sexe !== '') {
          this.producteurCreating = true;
          this.currentProducteur.sexe = this.sexe;
          producteurResource
            .store(this.currentProducteur)
            .then(async(response) => {
              this.$message({
                message: this.$t('producteur.NewProducteur') + ' ' + this.currentProducteur.libelle + ' ' + this.$t('producteur.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentProducteurId = response.data.id;
              this.resetNewProducteur();
              this.dialogCreateProducteur = false;
              this.handleFilterProducteurs();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.producteurCreating = false;
            });
        } else {
          console.log('Formulaire non valide !!');
          return false;
        }
      });
    },
    updateProducteur() {
      this.$refs['producteurForm'].validate((valid) => {
        if (valid) {
          this.producteurCreating = true;
          producteurResource.update(this.currentProducteur.id, this.currentProducteur).then(response => {
            this.resetNewProducteur();
            this.handleFilterProducteurs();
            this.$message({
              message: this.$t('producteur.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.dialogCreateProducteur = false;
            this.editing = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.producteurCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    resetNewProducteur() {
      this.currentProducteur = {
        libelle: '',
      };
      this.sexe = '';
    },
    createParcelle() {
      this.$refs['parcelForm'].validate((valid) => {
        if (valid) {
          this.currentParcelle.historiques = this.historiques;
          console.log('Parcel to be affected', this.currentParcelle);
          parcelleResource
            .store(this.currentParcelle)
            .then(async(response) => {
              this.$message({
                message: this.$t('parcelle.NewParcelle') + ' ' + this.$t('parcelle.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentParcelleId = response.data.id;
              this.affecterParcelleProducteur();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.parcelleAffectDialog = false;
              this.dialogCreateParcelle = false;
            });
        } else {
          console.log('Formulaire non valide !!');
          return false;
        }
      });
    },
    resetNewParcelle() {
      this.currentParcelle = {
        libelle: '',
      };
      this.sexe = '';
    },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['ID', this.$t('general.code'), this.$t('producteur.libelle')];
        const filterVal = ['id', 'code', 'libelle'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'Liste des producteurs SCIAGRI_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    onChange(file, fileList){
      var a = this.fileList;
      a.push({ name: file.name, url: file.url });
      // console.log('onChange begin');
      this.fileList = a;
      // console.log(this.fileList);
      // console.log('onChange end');
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-group {
  margin-top: 18px;

  .card-panel-col {
    margin-bottom: 32px;
  }

  .card-panel {
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);

    &:hover {
      .card-panel-icon-wrapper {
        color: #fff;
      }

      .icon-people {
        background: #40c9c6;
      }

      .icon-message {
        background: #36a3f7;
      }

      .icon-money {
        background: #f4516c;
      }

      .icon-shopping {
        background: #34bfa3
      }
    }

    .icon-people {
      color: #40c9c6;
    }

    .icon-message {
      color: #36a3f7;
    }

    .icon-money {
      color: #f4516c;
    }

    .icon-shopping {
      color: #34bfa3
    }

    .card-panel-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
      padding: 16px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }

    .card-panel-icon {
      float: left;
      font-size: 48px;
    }

    .card-panel-description {
      float: right;
      font-weight: bold;
      margin: 26px;
      margin-left: 0px;

      .card-panel-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        margin-bottom: 12px;
      }

      .card-panel-num {
        font-size: 20px;
      }
    }
  }
}

@media (max-width:550px) {
  .card-panel-description {
    display: none;
  }

  .card-panel-icon-wrapper {
    float: none !important;
    width: 100%;
    height: 100%;
    margin: 0 !important;

    .svg-icon {
      display: block;
      margin: 14px auto !important;
      float: none !important;
    }
  }
}
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
